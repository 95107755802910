import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import PageHeader from '../components/nav/PageHeader';
import Container from '../components/containers/Container';
import ResourceCard from '../components/ResourceCard';
import Footer from '../components/nav/Footer';
import coverImage from '../assets/images/cover.jpg';

export default ({ data, location }) => {
  const category = data.categoriesJson;
  const resources = category.items;

  resources.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <div>
      <Helmet
        title={`Fonoaudiologia - ${category.name}`}
        meta={[
          { name: 'keywords', content: 'fonoaudiologia, exercícios, fonoaudiológicos, recursos, cursos, vídeos, blogs, sites, aplicativos' },
          { name: 'og:locale', content: 'pt_BR' },
          { name: 'og:image', content: coverImage },
          { name: 'og:image:type', content: 'image/jpeg' },
          { name: 'og:image:width', content: '1200' },
          { name: 'og:image:height', content: '628' },
          { name: 'og:type', content: 'website' },
        ]}
      />

      <PageHeader
        title={category.name}
        emoji={category.emoji}
        hasBackButton
      />

      <Container style={{ marginTop: -50 }}>
        {resources.map(resource => (
          <ResourceCard
            key={resource.name}
            name={resource.name}
            description={resource.description}
            url={resource.url}
          />
        ))}
      </Container>

      <Footer pathname={location.pathname} />
    </div>
  );
};

export const query = graphql`
  query CategoryQuery($slug: String!) {
    categoriesJson(slug: { eq: $slug }) {
      name,
      emoji,
      items {
        name
        description
        url
        tags
      }
    }
  }
`;
