import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card, { CardTitle, CardDescription } from './panels/Card';
import Button from './actions/Button';

const ResourceCard = ({ name, description, url }) => (
  <CustomCard>
    <CardContent>
      <CardTitle>{name}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardContent>

    <Button href={url} target="_blank">Visitar</Button>
  </CustomCard>
);

const CustomCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 35px 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    ${Button} {
      margin-top: 25px;
    }
  }
`;

const CardContent = styled.div`
  @media (min-width: 768px) {
    padding-right: 20px;
  }
`;

ResourceCard.defaultProps = { description: undefined };
ResourceCard.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default ResourceCard;
